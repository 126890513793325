import { LanguageCode } from '@app-shared/enums/language-codes.enum';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const objectsShallowEquality = (object1: any, object2: any): boolean => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
};

export function updateHTMLLangProperty(document: Document, value: string | null | undefined): void {
  document.documentElement.setAttribute('lang', value || LanguageCode.ENGLISH_USA);
  forceOneTrustLanguageChange(document);
}

/**
 * Calls OneTrusts changeLanguage function which rerenders the cookie banner.
 *
 * @param document
 */
export function forceOneTrustLanguageChange(document: Document): void {
  const customWindow = document.defaultView as CustomWindow;
  if (customWindow) {
    customWindow.OneTrust?.changeLanguage();
  }
}
